/**
 * 租户
 */
import {
  axios
} from '@/utils/request'

/**
 * 租户列表
 *
 */
export function sysTenantPage(parameter) {
  return axios({
    url: '/sysTenant/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 新增租户
 *
 */
export function sysTenantAdd(parameter) {
  return axios({
    url: '/sysTenant/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除租户
 *
 */
export function sysTenantDelete(parameter) {
  return axios({
    url: '/sysTenant/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 编辑租户
 *
 */
export function sysTenantEdit(parameter) {
  return axios({
    url: '/sysTenant/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 拥有菜单
 *
 * @author zuohuaijun
 * @date 2021/04/22 10:30
 */
export function sysTenantOwnMenu(parameter) {
  return axios({
    url: '/sysTenant/ownMenu',
    method: 'get',
    params: parameter
  })
}
/**
 * 拥有菜单
 *
 * @author awenyjw
 * @date 2023-7-7 11:58:47
 */
export function sysTenantOwnMenuApplication(parameter) {
  return axios({
    url: '/sysTenant/ownMenuApplication',
    method: 'get',
    params: parameter
  })
}

export function sysRoleOwnMenuApplication(parameter) {
  return axios({
    url: '/sysRole/ownMenuApplication',
    method: 'get',
    params: parameter
  })
}


/**
 * 授权菜单
 *
 * @author awenyjw
 * @date 2023-5-12 16:21:26
 */
export function sysTenantGrantMenu(parameter) {
  return axios({
    url: '/sysTenant/grantMenu',
    method: 'post',
    data: parameter
  })
}

/**
 * 重置密码
 *
 * @author awenyjw
 * @date 2023-5-12 16:21:36
 */
export function sysTenantResetPwd(parameter) {
  return axios({
    url: '/sysTenant/resetPwd',
    method: 'post',
    data: parameter
  })
}
