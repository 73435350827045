<template>
  <a-modal
    title="租户授权菜单"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-list v-if="visible" :grid="{ gutter: 16, column: Applications.length }" :data-source="Applications">
      <a-list-item slot="renderItem" slot-scope="item, index">
        <a-card :title="item.title">
          <menuTemplate
            :key="item.id"
            @childReturnValue="handleChildReturnValue"
            :tenantEntity="tenantEntity"
            :app="item.app"
            :ref="item.app"
          />
        </a-card>
      </a-list-item>
    </a-list>
  </a-modal>
</template>
<script>
import { SysMenuTreeForGrant } from '@/api/modular/system/menuManage'
import { sysTenantOwnMenu, sysTenantGrantMenu } from '@/api/modular/system/tenantManage'
import menuTemplate from './menuTemplate.vue'
export default {
  data() {
    return {
      menuTreeData: [],
      expandedKeys: [],
      checkedKeys: [],
      halfCheckedKeys: [],
      visible: false,
      confirmLoading: false,
      formLoading: true,
      autoExpandParent: true,
      selectedKeys: [],
      subValues: [],
      tenantEntity: [],
      replaceFields: {
        key: 'id'
      },
      form: this.$form.createForm(this),
      radioApp: 'manage', //初始应用
      Applications: [{ id: 2, app: 'manage', title: '公司菜单' }]
    }
  },
  components: {
    menuTemplate
  },
  methods: {
    handleChildReturnValue(val) {},
    // 初始化方法
    tenantMenu(record, app) {
      this.formLoading = true
      this.tenantEntity = record
      console.log(this.tenantEntity,"===this.tenantEntity===");
      this.visible = true
      this.getMenuTree(app)
      this.expandedMenuKeys(record)
    },

    /**
     * 获取菜单列表
     */
    getMenuTree(app) {
      let params = {
        // Application:this.radioApp,
        Application: app
      }
      SysMenuTreeForGrant(params).then(res => {
        console.log(res.data, '===========================')
        if (res.success) {
          this.menuTreeData = res.data
          // 默认展开目录级
          this.menuTreeData.forEach(item => {
            this.expandedKeys.push(item.id)
          })
        }
      })
    },

    /**
     * 此租户已有菜单权限
     */
    expandedMenuKeys(record) {
      sysTenantOwnMenu({
        id: record.id
      }).then(res => {
        if (res.success) {

          console.log(res.data, '===============expandedMenuKeys============');
          this.checkedKeys = res.data
          this.findAllChildren(this.menuTreeData)
        }
        this.formLoading = false
      })
    },

    treeCheck(checkKeys, event) {
      this.halfCheckedKeys = event.halfCheckedKeys
    },
    
    handleSubmit() {
      let subData = []
      this.Applications.forEach(item => {
        let app = item.app
        subData.push(...(this.$refs[app].checkedKeys ?? []))
      })

      sysTenantGrantMenu({
        id: this.tenantEntity.id,
        grantMenuIdList: subData
      })
        .then(res => {
          if (res.success) {
            this.$message.success('授权成功')
            this.confirmLoading = false
            this.$emit('ok')
            this.handleCancel()
          } else {
            this.$message.error('授权失败：' + res.message)
          }
        })
        .finally(res => {
          this.confirmLoading = false
        })
    },
    handleCancel() {
      // 清空已选择的
      this.checkedKeys = []
      // 清空已展开的
      this.expandedKeys = []
      this.visible = false
    },

    // 遍历树形然后获取到对父节点进行移除，使用子节点，而且将父节点加入到mainMenuList
    findAllChildren(data) {
      data.forEach((item, index) => {
        if (item.children.length !== 0) {
          for (let i = 0; i < this.checkedKeys.length; i++) {
            if (item.id === this.checkedKeys[i]) {
              this.checkedKeys.splice(i, 1)
            }
          }
          this.findAllChildren(item.children)
        }
      })
    }
  }
}
</script>
